/* .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
    height: 240px;
} */

.image-gallery-thumbnails-wrapper
  .image-gallery-thumbnail
  .image-gallery-thumbnail-inner
  img {
  height: 60px;
}

/* Ensure all image-gallery-slide elements are the same size */
.image-gallery-slide {
  width: 100%;
  /* height: 200px;  */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Set all images to the same size */
.image-gallery-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Ensures the image covers the container without stretching */
}
.image-gallery-fullscreen-button {
  z-index: 1;
}
